.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before,
.react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}
.react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before,
.react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: $default-border;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}
.react-datepicker__tether-element-attached-top
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker__tether-element-attached-bottom
    .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker {
    font-family: $lato;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: $default-border;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: $default-radius;
    display: inline-block;
    position: relative;
    width: 217px;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
    margin-top: -20px;
}

.react-datepicker__header {
    text-align: center;
    font-weight: bold;
    border-bottom: $default-border;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    padding-top: 9px;
    position: relative;
}

.react-datepicker__header__dropdown--select {
    margin-top: -16px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}
.react-datepicker__current-month--hasYearDropdown {
    margin-bottom: 16px;
}

.react-datepicker__day-names {
    border-top: $default-border;
    margin-top: $default-margin * 0.5;
    font-weight: normal;
}
.react-datepicker__day-name {
    color: $text-medium;
}

.react-datepicker__navigation {
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
}
.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: $color-blue-highlight;
}
.react-datepicker__navigation--previous:hover {
    // border-right-color: #b3b3b3;
}
.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: $color-blue-highlight;
}
.react-datepicker__navigation--next:hover {
    // border-left-color: #b3b3b3;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}
.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}
.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    display: inline;
    float: left;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day-name,
.react-datepicker__day {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day-name {
    color: $text-medium;
}

.react-datepicker__day {
    cursor: pointer;
}
.react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--today {
    font-weight: bold;
}
.react-datepicker__day--highlighted {
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: $color-blue-highlight;
    color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
}
.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}

.match-parent .react-datepicker__input-container,
.match-parent > div > div {
    width: 100%;
}

.react-datepicker__day--keyboard-selected {
    background: #ccc;
    opacity: 0.7;
}

.react-datepicker__year-read-view {
    width: 50%;
    left: 25%;
    position: absolute;
    bottom: 25px;
    border: 1px solid transparent;
    border-radius: 0.3rem;
}
.react-datepicker__year-read-view:hover {
    cursor: pointer;
}
.react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow {
    border-top-color: #ccc;
    margin-bottom: 3px;
    left: 5px;
    top: 9px;
    position: relative;
    border-width: 0.45rem;
}
.react-datepicker__year-read-view--selected-year {
    right: 0.45rem;
    position: relative;
}

.react-datepicker__year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover {
    cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover {
    background-color: #ccc;
}
.react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected {
    position: absolute;
    left: 30px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
}
.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
}

.react-datepicker__tether-element {
    z-index: 2147483647;
}

/* Fixes rendering in Form Document
   Sorry about this !important spam

   react-datepicker is very difficult to override.
 */

.react-datepicker__input-container {
    position: static;
    background: orange !important;
    width: inherit !important;
}

.input-item.field-container {
    border: none !important;
    padding: 2px !important;
    > div {
        width: inherit !important;
        > div {
            width: inherit !important;
        }
    }
}

.field-container {
    background: none !important;
    position: absolute;
    .input-item {
        position: absolute;
        left: 0;
        bottom: 0;
        color: inherit !important;
        width: inherit !important;
        font-size: inherit !important;
    }
}

.react-datepicker__day--outside-month {
    color: $text-light;

    &.react-datepicker__day--selected {
        color: $color-white;
    }
}
